import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Pagination,
  TableFooter,
  TablePagination,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  CustomTableContainer,
  CustomTableHead,
  // TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RemoveRedEye,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { DisplayText } from "../../bankDetails/components/bankAccountsContent";
import empty from "../../../assets/illo.png";
import { getOrders, getUploadPOinvoice } from "../redux/userOrdersSlice";
import { Link } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import { FormControl } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { message } from "antd";
import { styled } from "@mui/material/styles";
import { StyledOutlineButton } from "../../vendorRegistration/components/StepMain";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { getDirectOrders } from "../../adminOrders/redux/adminOrdersSlice";
const EmptyContainer = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <br />
    <img src={empty} alt="empty" />

    <DisplayText> There are no orders to display</DisplayText>

    <br />

    <br />
  </Grid>
);

const Input = styled("input")({
  display: "none",
});

function UserOrdersTableContent({ setPages, setPerPages, data, refetch }) {
  const { pending } = useSelector((state) => state.pendinglist);
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState("");
  const [document, setDocument] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setDocument("");
    clearErrors("invoice_amount");
    setOpenDialog(false);
  };

  const onSubmit = (data) => {
    setLoading(true);

    const formData = new FormData();
    const payload = {
      PurchaseOrderNo: data.PurchaseOrderNo,
      SupplierCode: data.SupplierCode,
      invoice_amount: data.invoice_amount,
      invoice_number: data.invoice_number,
    };

    formData.append("data", JSON.stringify(payload));
    formData.append("PurchaseOrderDocument", document);

    dispatch(getUploadPOinvoice(formData)).then((res) => {
      if (res.payload.success) {
        refetch();
        setDocument("");
        setLoading(false);
        setOpenDialog(false);
        message.success(res.payload.message);
      } else {
        refetch();
        setLoading(false);
        setOpenDialog(false);

        message.error(res.payload.message);
      }
    });
  };
  useEffect(() => {
    setPages(page);
    setPerPages(per_page);
  }, [page, per_page]);
  console.log("datawww", data);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ fontWeight: "Bold" }}>Create Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContentText>PO Number</DialogContentText>
              <TextField
                style={{ width: "100%" }}
                sx={{
                  input: {
                    fontSize: "14px",
                    fontFamily: "var(--font-family-reg)",
                  },
                }}
                value={selectedRow?.order_number}
                variant="standard"
                name="PurchaseOrderNo"
                {...register("PurchaseOrderNo", {
                  value: selectedRow?.order_number,
                })}
                disabled
              />
              <br />
              <br />
              <DialogContentText>
                Invoice Number<Super>*</Super>
              </DialogContentText>
              <TextField
                style={{ width: "100%" }}
                sx={{
                  input: {
                    fontSize: "14px",
                    fontFamily: "var(--font-family-reg)",
                  },
                }}
                type="text"
                variant="standard"
                name="invoice_number"
                {...register("invoice_number")}
                onChange={(e) => {
                  setValue("invoice_number", String(e.target.value));
                }}
              />
              <br />
              <br />
              <DialogContentText>Supplier Code</DialogContentText>
              <TextField
                style={{ width: "100%" }}
                sx={{
                  input: {
                    fontSize: "14px",
                    fontFamily: "var(--font-family-reg)",
                  },
                }}
                value={
                  data?.data?.find((x) => x.id === selectedRow?.id)?.Vendor
                    ?.User?.UsersRoles?.[0]?.vendor_code || ""
                }
                variant="standard"
                name="SupplierCode"
                {...register("SupplierCode", {
                  value:
                    data?.data?.find((x) => x.id === selectedRow?.id)?.Vendor
                      ?.User?.UsersRoles?.[0]?.vendor_code || "",
                })}
                disabled
              />
              <br />
              <br />
              <DialogContentText>
                Invoice Amount<Super>*</Super>
              </DialogContentText>
              <TextField
                style={{ width: "100%" }}
                type="number"
                sx={{
                  input: {
                    fontSize: "14px",
                    fontFamily: "var(--font-family-reg)",
                  },
                }}
                placeholder="Enter Invoice Amount"
                variant="standard"
                name="invoice_amount"
                {...register("invoice_amount", {
                  required: "Please enter Invoice Amount",
                  valueAsNumber: true,
                })}
                onChange={(e) => {
                  const value = Number(e.target.value);

                  if (value < 0) {
                    setError("invoice_amount", {
                      type: "manual",
                      message: "Amount cannot be negative",
                    });
                    setIsSubmitDisabled(true);
                  } else if (value === 0) {
                    setError("invoice_amount", {
                      type: "manual",
                      message: "Amount cannot be 0",
                    });
                    setIsSubmitDisabled(true);
                  } else if (
                    selectedRow?.remaining_amount &&
                    value > selectedRow?.remaining_amount
                  ) {
                    setError("invoice_amount", {
                      type: "manual",
                      message: `Amount cannot exceed ${selectedRow.remaining_amount}`,
                    });
                    setIsSubmitDisabled(true); // Disable button
                  } else {
                    clearErrors("invoice_amount"); // Clears error if valid
                    setIsSubmitDisabled(false); // Enable button
                  }

                  setValue("invoice_amount", value); // Ensures form state updates correctly
                }}
                error={Boolean(errors?.invoice_amount)}
                helperText={errors.invoice_amount?.message}
              />
              <br />
              <br />
              <DialogContentText>
                Invoice Document <Super>*</Super>
              </DialogContentText>

              {document !== "" ? (
                <>
                  <span
                    style={{
                      color: "#4945FF",
                      fontSize: "13px",
                      fontFamily: "var(–font-family-reg)",
                    }}
                  >
                    <Chip
                      style={{
                        width: "150px",
                        color: "#4945FF",
                        margin: "5px",
                        borderRadius: "5px",
                        border: "1px solid #D9D8FF",
                        background: "#F0F0FF",
                      }}
                      label={document.name}
                      value={document.name}
                      name="chips"
                    />
                    <HighlightOffIcon
                      sx={{ color: "#4945FF", paddingBottom: "2px" }}
                      fontSize="medium"
                      onClick={(e) => setDocument("")}
                    />
                  </span>
                </>
              ) : (
                <label htmlFor="PurchaseOrderDocument">
                  <Input
                    {...register("PurchaseOrderDocument", {
                      required: "Please upload Purchase Order Document",
                    })}
                    accept="*"
                    id="PurchaseOrderDocument"
                    onChange={(e) => setDocument(e.target.files[0])}
                    type="file"
                  />
                  <StyledOutlineButton variant="outlined" component="span">
                    Upload
                  </StyledOutlineButton>
                </label>
              )}
              {document === "" ? (
                <FormHelperText error>
                  {errors.PurchaseOrderDocument?.message}
                </FormHelperText>
              ) : (
                ""
              )}

              <Grid
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  disabled={isSubmitDisabled}
                  loadingPosition="start"
                >
                  Submit
                </LoadingButton>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}></DialogActions>
      </Dialog>
      <CustomTableContainer component={Card}>
        <Table aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              {/* <TableCell>
                <TableHeaderFont>Vendor Company Name</TableHeaderFont>
              </TableCell> */}
              {/* <TableCell>
                <TableHeaderFont>Supplier Code</TableHeaderFont>
              </TableCell> */}
              <TableCell align="left">
                <TableHeaderFont>Order Number</TableHeaderFont>
              </TableCell>

              <TableCell align="left">
                <TableHeaderFont>Order Amount</TableHeaderFont>
              </TableCell>

              <TableCell align="left">
                <TableHeaderFont>Order Date</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Order Acceptance Date</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Status</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Invoice Status</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Invoice Date</TableHeaderFont>
              </TableCell>
              <TableCell align="center">
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell>
            </TableRow>
          </CustomTableHead>
          {data?.data?.length !== 0 ? (
            <TableBody>
              {data?.data?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {row?.order_number ? row?.order_number : "Not Applicable"}
                  </TableCell>
                  <TableCell>
                    {row?.order_amount ? row?.order_amount : "Not Applicable"}
                  </TableCell>

                  <TableCell>
                    {row.order_date
                      ? moment(row.order_date).format("DD MMM YY")
                      : "Not Applicable"}
                  </TableCell>
                  <TableCell>
                    {row.status === "Accepted"
                      ? row.updatedAt
                        ? moment(row.updatedAt).format("DD MMM YY")
                        : "-"
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row.status ? (
                      <Chip
                        label={row.status.toUpperCase()}
                        color={row.status === "Pending" ? "warning" : "success"}
                        size="small"
                        style={{ fontSize: "9px" }}
                      />
                    ) : (
                      "Not Applicable"
                    )}
                  </TableCell>
                  <TableCell>
                    {row?.Invoices?.length ? (
                      <Chip
                        label={"SUBMITTED"}
                        color={"success"}
                        size="small"
                        style={{ fontSize: "9px" }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {row?.Invoices?.length
                      ? moment(
                          row.Invoices.reduce((latest, invoice) =>
                            new Date(invoice.createdAt) >
                            new Date(latest.createdAt)
                              ? invoice
                              : latest
                          ).updatedAt
                        ).format("DD MMM YY")
                      : "Not Applicable"}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Tooltip title="View Order">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line

                        to={"/app/user-orders-list-view/" + `${row.id}`}
                      >
                        <RemoveRedEye
                          style={{
                            fill: "royalblue",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      </Link>
                    </Tooltip>
                    <IconButton
                      onClick={() => {
                        setDocument("");
                        setSelectedRow(row);
                        setOpenDialog(true);
                      }}
                    >
                      {row?.status === "Accepted" && (
                        <Tooltip title="Create Invoice">
                          <AddBoxOutlinedIcon color="action" fontSize="small" />
                        </Tooltip>
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={9}>
                  <CustomTableContainer>
                    {/* Todo fix permissions */}
                    <EmptyContainer />
                  </CustomTableContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={data?.pagination?.total_page}
            page={page}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
    </>
  );
}

export default UserOrdersTableContent;
