import React, { useEffect } from "react";
import {
  Grid,
  Button,
  MenuItem,
  Menu,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  // FormControl,
  // Select,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { OfficerAdminTableContent } from "./components/officerAdminTableContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import { VendorCardLabel } from "../vendorRegistration/components/StepMain";
import { Super } from "../vendorRegistration/components/steps/cardTrial";
import { useDispatch, useSelector } from "react-redux";
import { companyListForOfficer, invitationLink } from "./redux/sendLinkSlice";
import { useForm } from "react-hook-form";
import { check_workflow_step_thunk } from "../workflows/redux/userWorkflowsSlice";
// import { history } from "../../app/history";
import GlobalAutocomplete from "../../common/sharedComponents/AutoComplate";
import { useLazyGetCategorySAPwiseQuery } from "../../utilities/redux-api/vendor_category/api";
import LocalStorage from "../../utilities/localStorage";

const OfficerTable = () => {
  const { loading, companyList } = useSelector(
    (state) => state.sendInvitationLink
  );
  const { stepDefined } = useSelector((state) => state.userWorkflows);
  const clientId = LocalStorage.getItem("client")?.id;
  const dispatch = useDispatch();
  const checkWorkflowStep = () => {
    dispatch(check_workflow_step_thunk("Vendor"));
  };
  const [trigger, { data }] = useLazyGetCategorySAPwiseQuery();
  // const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [clicked, setClicked] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    // resetField,
  } = useForm();

  const onCompanySelect = (data) => {
    trigger(data?.key);
    setValue("category", null);
  };

  const handleSave = (data) => {
    const payload = {
      email: data?.email,
      cid: data?.company?.key,
      vcm_id: data?.category?.key,
    };
    dispatch(invitationLink(payload)).then((response) => {
      reset();
      if (response?.payload?.success) {
        setOpen(false);
      } else {
        reset();
        setOpen(false);
      }
    });
  };
  useEffect(() => {
    dispatch(companyListForOfficer(""));
  }, [dispatch]);
  useEffect(() => {
    if (open) {
      checkWorkflowStep();
    }
    // eslint-disable-next-line
  }, [open]);
  useEffect(() => {
    if (!stepDefined) {
      setOpen(false);
    }
  }, [stepDefined, open]);

  const handleSearchFilterChange = (event) => {
    setSearchFilter(event.target.value);
  };
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "5px" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <Grid sx={{ whiteSpace: "nowrap" }} item>
            Vendors
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
            >
              <FormControl variant="outlined" size="small">
                <InputLabel>Filter</InputLabel>
                <Select
                  value={searchFilter}
                  onChange={handleSearchFilterChange}
                  label="Filter"
                  sx={{ width: "200px" }}
                >
                  <MenuItem value="company_name">Company Name</MenuItem>
                  <MenuItem value="company_email">Company Email</MenuItem>
                  <MenuItem value="permanent_account_number">
                    Permanent Account Number
                  </MenuItem>
                  <MenuItem value="gst_registration_type">
                    GST Registration Type
                  </MenuItem>

                  <MenuItem value="contact_person_email">
                    Contact Person Email
                  </MenuItem>
                  <MenuItem value="vendor_code">Vendor Code</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                size="large"
                sx={{ marginLeft: "5px" }}
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button>
            </Grid>
            {/* dropdown */}
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "2rem",
                      height: "2.5rem",
                      width: "4.3rem",
                    }}
                    {...bindTrigger(popupState)}
                  >
                    +
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={popupState.close}>
                      <Link
                        to="/app/add-vendor"
                        onClick={checkWorkflowStep}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Vendor Registration
                      </Link>
                    </MenuItem>
                    <MenuItem
                      onClick={
                        (popupState.close,
                        checkWorkflowStep,
                        () => setOpen(true))
                      }
                    >
                      Invite Vendor
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
        </Grid>
      </TitleHolder>

      <StepperContentHolder style={{ paddingTop: "5px" }}>
        <OfficerAdminTableContent
          search={search}
          clicked={clicked}
          setClicked={setClicked}
          column={searchFilter}
        />
      </StepperContentHolder>

      {/* add file dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontWeight: "Bold" }}>
          Send Link
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Add an excel file to upload</DialogContentText>
          <br />
          <DialogContentText>
            <form onSubmit={handleSubmit(handleSave)}>
              <Grid container spacing={1}>
                <Grid
                  item
                  sx={{
                    width: "100%",
                  }}
                >
                  <VendorCardLabel>
                    Vendor Email<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    style={{ width: "100%" }}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter Vendor Email"
                    variant="standard"
                    name="email"
                    {...register("email", {
                      required: "please enter email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "please enter valid email",
                      },
                    })}
                    error={Boolean(errors?.email)}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "100%",
                  }}
                >
                  <GlobalAutocomplete
                    label={"Select Company"}
                    name={"company"}
                    placeholder={"Select a Company"}
                    options={companyList.map((company, index) => {
                      return {
                        key: company.id,
                        value: company?.company_name,
                      };
                    })}
                    control={control}
                    rules={{ required: `Company is required` }}
                    onSelect={onCompanySelect}
                  />
                </Grid>
                {clientId === 2 && (
                  <Grid
                    item
                    sx={{
                      width: "100%",
                    }}
                  >
                    <GlobalAutocomplete
                      label={"Select Vendor Category"}
                      name={"category"}
                      placeholder={"Select a Vendor Category"}
                      options={
                        data?.data?.map((el, index) => {
                          return {
                            key: el.id,
                            value: `${el.type} (${el?.type_code})`,
                          };
                        }) ?? []
                      }
                      control={control}
                      rules={{ required: `Vendor Category is required` }}
                      onSelect={() => {}}
                    />
                  </Grid>
                )}
              </Grid>
              <DialogActions style={{ alignItems: "right" }}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                >
                  Send Link
                </LoadingButton>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OfficerTable;
