import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_orders = (payload) => {
  return apiClient.get(`${apiClient.Urls.order_numbers}`, payload, true);
};
export const vendorDirectOrdersList = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.vendor_direct_orders_list}`,
    payload,
    true
  );
};

export const view_OrderDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_order_details}/${payload}`,
    "",
    true
  );
};
export const view_ledgerDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.order_ledger_details}/${payload}`,
    "",
    true
  );
};
export const view_invoiceDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.order_invoices_details}/${payload}`,
    "",
    true
  );
};
export const uploadPOinvoice = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.uploadPOinvoice}`,
    payload,
    true,
    "file"
  );
};

export const approve_order = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.order_approve}/${payload.order_id}`,
    payload,
    true
  );
};
