import apiClient from "../../../utilities/apiClient";

export const get_pr_granted_users = (page, per_page) => {
  return apiClient.get(
    `${apiClient.Urls.get_pr_granted_users}?page=${page}&per_page=${per_page}`,
    "",
    true
  );
};

export const get_pr_pending_users = (page, per_page) => {
  return apiClient.get(
    `${apiClient.Urls.get_pr_pending_users}?page=${page}&per_page=${per_page}`,
    "",
    true
  );
};

export const get_pr_revoked_users = (page, per_page) => {
  return apiClient.get(
    `${apiClient.Urls.get_pr_revoked_users}?page=${page}&per_page=${per_page}`,
    "",
    true
  );
};

export const grant_pr_privilege = (payload) => {
  return apiClient.post(`${apiClient.Urls.grant_pr_privilege}`, payload, true);
};

export const revoke_pr_privilege = (payload) => {
  return apiClient.post(`${apiClient.Urls.revoke_pr_privilege}`, payload, true);
};
export const user_PrivilegesListForLedger = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.getUserPrivilegesListForLedger}?page=${payload.page}&per_page=${payload.per_page}&status=${payload.status}&doc_type=1&search=${payload.search}`,
    "",
    true
  );
};
export const user_PrivilegesListForDirectOrder = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.getUserPrivilegesListForLedger}?page=${
      payload.page
    }&per_page=${payload.per_page}&status=${payload.status}&doc_type=3&search=${
      payload.search || ""
    }`,
    "",
    true
  );
};
export const user_PrivilegesListForRefOrder = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.getUserPrivilegesListForLedger}?page=${
      payload.page
    }&per_page=${payload.per_page}&status=${payload.status}&doc_type=4&search=${
      payload.search || ""
    }`,
    "",
    true
  );
};
export const user_privilegesGrantsForLedger = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.userPrivilegesGrantsForLedger}`,
    payload,
    true
  );
};

export const user_PrivilegesListForPR = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.getUserPrivilegesListForPR}?page=${
      payload.page
    }&per_page=${payload.per_page}&status=${payload.status}&doc_type=2&search=${
      payload.search || ""
    }`,
    "",
    true
  );
};
