import React, { useEffect, useState } from "react";
// import logo from "../../assets/TataLogo.png";
// import bg from "../../assets/background.png";
import navitas_bg from "../../assets/navitas-bg.png";
import vishaka_bg from "../../assets/vishaka_bg.jpg";
import jaslok_bg from "../../assets/jaslok_bg.jpeg";
import sg_bg from "../../assets/sg-bg.png";
// import { LinearProgress, Box } from "@mui/material";
// import {
//   Container,
//   DemoButtons,
//   ImageContainer,
//   LogoContainer,
// } from "./styles/authLeft.style";
import { Modal, Table } from "antd";
import { Header } from "antd/lib/layout/layout";
import success from "../../assets/success.svg";
import styled from "styled-components";
import { bank_info } from "../../utilities/helper";
import { useDispatch } from "react-redux";
import { tenant } from "../../features/login/redux/loginSlice";
import { domainName } from "../../utilities/helper";

// import PDF from "../../assets/UserManual.pdf"

function LeftAuth() {
  // const { loading } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [client, setClient] = useState({});
  console.log("client: ", client);

  const dataSource = [
    {
      key: "1",
      activity: "Vendor Code Creation and Updates / Login Creation",
      contact: "Dhanraj",
      email: "marketing@procuregenie.com",
      contactno: " +918980000089",
      hours: "10am to 7pm",
    },
  ];

  const columns = [
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: "Contact Name",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Contact Details",
      dataIndex: "contactno",
      key: "contactno",
    },
    {
      title: "Business Hours",
      dataIndex: "hours",
      key: "hours",
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [isSupportModalVisible, setIsSupportModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const showVideoModal = () => {
  //   setIsVideoModalVisible(true);
  // };

  // const showSupportModal = () => {
  //   setIsSupportModalVisible(true);
  // };

  const handleSupportOk = () => {
    setIsSupportModalVisible(false);
  };

  const handleSupportCancel = () => {
    setIsSupportModalVisible(false);
  };

  const handleVideoOk = () => {
    setIsVideoModalVisible(false);
  };

  const handleVideoCancel = () => {
    setIsVideoModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const getClient = async () => {
  //   const getClientInLocal = LocalStorage.getItem("client");
  //   setInterval(() => {
  //     if (getClientInLocal) {
  //       setClient(getClientInLocal?.id);
  //     } else {
  //       dispatch(clientClear());
  //       dispatch(tenant({ domain_url: domainName })).then((res) => {
  //         if (res.payload.success) {
  //           setClient(res.payload.data?.id);
  //         }
  //       });
  //     }
  //   }, 2000);
  // };
  // useEffect(() =>{
  //   dispatch(tenant({ domain_url: domainName })).then((res) => {
  //     if (res.payload.success) {
  //       setClient(res.payload.data?.id);
  //     }
  //   });
  // },[dispatch])
  // getClient();

  return (
    <div>
      {/* {loading ? (
        <div>
          <Box sx={{ width: "100%", height: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : ( */}
      <div
        className="col-md-7 p-0 h-md-100 d-none d-xs-none d-sm-none d-md-none d-lg-block"
        style={
          client === 5
            ? {
                backgroundImage: `url(${jaslok_bg})`,
                height: "100vh",
                width: "100vw",
                backgroundSize: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                // width: "100%",
              }
            : client === 1
            ? {
                backgroundImage: `url(${navitas_bg})`,
                height: "100vh",
                width: "100vw",
                backgroundSize: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                // width: "100%",
              }
            : client === 2
            ? {
                backgroundImage: `url(${vishaka_bg})`,
                height: "100vh",
                width: "100vw",
                backgroundSize: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                // width: "100%",
              }
            : {
                backgroundImage: `url(${sg_bg})`,
                height: "100vh",
                width: "100vw",
                backgroundSize: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                // width: "100%",
              }
        }
      >
        {/* <LogoContainer>
        {client === 1 || client === 2 ? <br /> : <img src={logo} alt="logo" />}
      </LogoContainer> */}

        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Container style={{ width: "100%", justifyContent: "inherit" }}>
          <DemoButtons onClick={showModal} to="/login">
            Document Checklist
          </DemoButtons>
          <DemoButtons onClick={showVideoModal} to="/login">
            Help Video
          </DemoButtons>
          <DemoButtons onClick={showSupportModal} to="/login">
            Support
          </DemoButtons>
        </Container>
      </div> */}
        <StyledModal
          title="For vendors with addresses in India"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <StyledModalHeader>
            Documents required for vendor registration:
          </StyledModalHeader>
          <p>
            <img src={success} alt="1" /> PAN certificate
          </p>
          <p>
            <img src={success} alt="2" /> GST certificate (for all locations)
          </p>
          <p>
            <img src={success} alt="3" /> MSMED certificate (if applicable)
          </p>
          <p>
            <img src={success} alt="4" /> Dealer authorisation certificates (if
            applicable)
          </p>
        </StyledModal>

        <StyledModal
          destroyOnClose={true}
          title="Explainer"
          visible={isVideoModalVisible}
          onOk={handleVideoOk}
          onCancel={handleVideoCancel}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          {/* <video width="100%" height="100%" controls>
          <source src={explainer} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        </StyledModal>

        <StyledModal
          width={1000}
          title="Support"
          visible={isSupportModalVisible}
          onOk={handleSupportOk}
          onCancel={handleSupportCancel}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <h4>Primary Contacts:</h4>

          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </StyledModal>
        {/* <div>
        {client === 1 || client === 2 ? (
          ""
        ) : (
          <ImageContainer>
            <img src={bg} alt="background" />
          </ImageContainer>
        )}
      </div> */}
      </div>
      {/* )} */}
    </div>
  );
}

export default LeftAuth;

export const StyledModal = styled(Modal)`
  p {
    font-family: var(--font-family-semi-bold);
    font-size: 12px;
    font-weight: 400;
  }
  h4 {
    font-family: var(--font-family-semi-bold);
    font-size: 16px;
    font-weight: 400;
  }
  img {
    height: 12px;
  }
  .ant-modal-header {
    padding: 16px 24px;
    color: #f0f0f0;
    background: var(--button-color);
    border-bottom: 1px solid #f0f0f0;
    border-radius: 10px 10px 0 0;
  }
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-title {
    font-family: var(--font-family-semi-bold);
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    word-wrap: break-word;
  }
  .anticon svg {
    color: white;
    display: inline-block;
  }

  .ant-btn-primary {
    font-family: var(--font-family-semi-bold);
    border-radius: 5px;
    color: #fff;
    border-color: var(--button-color);
    background: var(--button-color);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .ant-btn-primary :hover {
    color: white;
  }

  .ant-btn:hover {
    color: white;
    border: 1px solid var(--button-color);
    background-color: var(--button-color);
  }
  .ant-modal-footer {
    text-align: center;
  }
`;

export const StyledModalHeader = styled(Header)`
  background-color: white;
  font-family: var(--font-family-semi-bold);
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  word-wrap: break-word;
`;
