import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";

import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import UserRefOrdersTableContent from "./components/userRefOrdersTableContent";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { getRefOrders } from "../adminOrders/redux/adminOrdersSlice";
import { Button } from "antd";
function UserRefOrdersTable() {
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const { refOrdersList } = useSelector((state) => state.adminOrders);
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");

  const orders = (payload) => {
    dispatch(getRefOrders(payload));
  };

  React.useEffect(() => {
    orders({
      page: page,
      per_page: per_page,
      search: search,
      column: searchFilter,
    });
  }, [page, per_page]);
  const handleSearchClick = () => {
    orders({
      page: page,
      per_page: per_page,
      search: search,
      column: searchFilter,
    });
  };
  const handleSearchClear = () => {
    setSearch("");
  };
  const handleSearchFilterChange = (event) => {
    setSearchFilter(event.target.value);
  };
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ whiteSpace: "nowrap" }} item>
            Referenced Orders
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" size="small">
                <InputLabel>Filter</InputLabel>
                <Select
                  value={searchFilter}
                  onChange={handleSearchFilterChange}
                  label="Filter"
                  sx={{ width: "200px" }}
                >
                  <MenuItem value="company_name">Company Name</MenuItem>
                  <MenuItem value="vendor_code">Vendor Code</MenuItem>
                  <MenuItem value="order_number">Order Number</MenuItem>
                  {/* <MenuItem value="order_amount">Order Amount</MenuItem> */}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <Button
                variant="contained"
                size="large"
                onClick={handleSearchClick}
              >
                <Search />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <UserRefOrdersTableContent
          setPages={setPage}
          setPerPages={setPer_page}
          data={refOrdersList}
        />
      </StepperContentHolder>
    </div>
  );
}

export default UserRefOrdersTable;
