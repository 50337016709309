import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { styled, useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { useDispatch, useSelector } from "react-redux";
import {
  getPrGrantedUsers,
  getPrPendingUsers,
  getPrRevokedUsers,
  userPrivilegesListForLedger,
} from "../redux/userPrivilegesSlice";
import LedgerTableContent from "./ledgerTable/ledgerTableContent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CustomBox
          sx={{
            pt: 3,
            backgroundColor: "#f5f7f9",
          }}
        >
          <div>{children}</div>
        </CustomBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function LedgerCreationTableViewContent({ search }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [per_page, setPer_page] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const { ledgerPrivilegesList, loading } = useSelector(
    (state) => state.userPrivileges
  );
  const userPrivilegesList = (payload) => {
    dispatch(userPrivilegesListForLedger(payload));
  };
  React.useEffect(() => {
    userPrivilegesList({
      page: page,
      per_page: per_page,
      status: value + 1,
      search: search,
    });
    // eslint-disable-next-line
  }, [page, per_page, value, search]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const refetch = () => {
    userPrivilegesList({
      page: page,
      per_page: per_page,
      status: value + 1,
      search: search,
    });
  };
  return (
    <>
      <Box sx={{ boxShadow: "0px 20px 30px rgba(52, 173, 237, 0.2)" }}>
        <CustomTab
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Pending" {...a11yProps(0)} />
          <Tab label="Granted" {...a11yProps(1)} />
          <Tab label="Revoked" {...a11yProps(2)} />
        </CustomTab>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <LedgerTableContent
              type={"pending"}
              setPages={setPage}
              setPerPages={setPer_page}
              data={ledgerPrivilegesList}
              isLoading={loading}
              refetch={refetch}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <LedgerTableContent
              type={"granted"}
              setPages={setPage}
              setPerPages={setPer_page}
              data={ledgerPrivilegesList}
              isLoading={loading}
              refetch={refetch}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <LedgerTableContent
              type={"revoked"}
              setPages={setPage}
              setPerPages={setPer_page}
              data={ledgerPrivilegesList}
              isLoading={loading}
              refetch={refetch}
            />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </>
  );
}

export const CustomBox = styled(Box)`
  background-color: #f5f7f9;
  /*   box-shadow: 0px 0px 20px rgba(52, 173, 237, 0.2); */
  border-radius: 5px;
  /* filter: drop-shadow(0px 0px 0px 20px rgba(52, 173, 237, 0.2)); */
`;

export const CustomTab = styled(Tabs)`
  background-color: #f5f7f9;
  .css-11yukd5-MuiTabs-indicator {
    background-color: #4059e5;
  }
`;
