import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_vendors = (payload) => {
  return apiClient.get(`${apiClient.Urls.vendors_list}`, payload, true);
};

export const get_materials = (payload) => {
  return apiClient.get(`${apiClient.Urls.materials_list}`, payload, true);
};

export const get_vendor_emails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.vendors_list}?vendor_id=${payload}`,
    "",
    true
  );
};

export const orders = (payload) => {
  return apiClient.post(`${apiClient.Urls.orders}`, payload, true, "file");
};

export const view_AdminOrderDetails = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  return apiClient.get(
    //Todo fix permissions
    role[0] === "Admin" || role[0] === "finance"
      ? `${apiClient.Urls.admin_order_view}/${payload}`
      : `${apiClient.Urls.view_admin_order_details}/${payload}`,
    "",
    true
  );
};

export const get_orders = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  //Todo fix permissions
  return apiClient.get(
    role[0] === "Admin" || role[0] === "finance"
      ? `${apiClient.Urls.admin_order_list}`
      : `${apiClient.Urls.orders_list}`,
    payload,
    true
  );
};
export const get_direct_orders = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  console.log("roleeee", role);

  return apiClient.get(
    role[0] === "officer"
      ? `${apiClient.Urls.direct_orders_list}?page=${payload.page}&per_page=${
          payload.per_page
        }&search=${payload.search || ""}&column=${payload.searchFilter || ""}`
      : role[0] === "finance" || role[0] === "Admin"
      ? `${apiClient.Urls.finance_direct_vendor_orders_list}?page=${
          payload.page
        }&per_page=${payload.per_page}&search=${payload.search || ""}&column=${
          payload.searchFilter || ""
        }`
      : `${apiClient.Urls.direct_vendor_orders_list}?page=${
          payload.page
        }&per_page=${payload.per_page}&search=${payload.search || ""}&column=${
          payload.searchFilter || ""
        }`,
    "",
    true
  );
};
export const get_ledger_status = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  console.log("roleeee", role);

  return apiClient.get(
    role[0] === "officer"
      ? apiClient.Urls.officer_ledger_status
      : apiClient.Urls.admin_ledger_status,
    payload,
    true
  );
};
export const get_ref_orders = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  console.log("roleeee", role);
  return apiClient.get(
    role[0] === "officer"
      ? apiClient.Urls.ref_orders_list
      : role[0] === "finance" || role[0] === "Admin"
      ? apiClient.Urls.finance_ref_vendor_orders_list
      : apiClient.Urls.ref_vendor_orders_list,
    payload,
    true
  );
};
// export const get_direct_orders = (payload) => {
//   return apiClient.get(apiClient.Urls.direct_orders_list, payload, true);
// };

export const add_excel_data = (payload) => {
  return apiClient.post(`${apiClient.Urls.add_excel_orders}`, payload, true);
};
export const createPOLeisure = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.createPOLeisure}`,
    payload,
    true,
    "file"
  );
};
