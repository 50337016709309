import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Card,
  Grid,
  IconButton,
  TableFooter,
  TablePagination,
  useTheme,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  styled,
  FormHelperText,
  Button,
  DialogActions,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Autocomplete,
  Pagination,
} from "@mui/material";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  SelectSpan,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RemoveRedEye,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import {
  ButtonLink,
  DisplayText,
} from "../../bankDetails/components/bankAccountsContent";
import empty from "../../../assets/illo.png";
import {
  getCreatePOLeisure,
  getDirectOrders,
  getLedgerStatus,
  getOrders,
} from "../redux/adminOrdersSlice";
import moment from "moment";
import { Link } from "react-router-dom";
import LocalStorage from "../../../utilities/localStorage";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { message } from "antd";
import { getInvoiceDetails } from "../../userOrders/redux/userOrdersSlice";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";

const EmptyContainer = ({ role }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <br />
    <img src={empty} alt="empty" />
    <DisplayText> There are no orders to display</DisplayText>
    <br />
    {/* {role === "Admin" || role === "finance" ? (
      ""
    ) : (
      <ButtonLink to="/app/orders">+ Add Orders</ButtonLink>
      
    )} */}

    <br />
    <br />
  </Grid>
);

const Input = styled("input")({
  display: "none",
});

function AdminOrdersTableContent({ setPages, setPerPages, data }) {
  const { pending } = useSelector((state) => state.pendinglist);
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const { directOrdersList, ledgerStatusData } = useSelector(
    (state) => state.adminOrders
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState("");
  const [document, setDocument] = React.useState("");
  const [paymentType, setPaymentType] = React.useState("");
  const [invoiceOptionData, setInvoiceOptionData] = React.useState("");
  const [invoiceRemainingAmountData, setInvoiceRemainingAmountData] =
    React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [deduction, setDeduction] = React.useState(false);
  const dispatch = useDispatch();
  const role = LocalStorage.getItem("tata_login_user").roles;
  const { invoice_details } = useSelector((state) => state.userOrders);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    watch,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setPages(page);
    setPerPages(per_page);
  }, [page, per_page]);
  useEffect(() => {
    dispatch(getLedgerStatus());
  }, []);

  const handleClose = () => {
    setDocument("");
    setOpenDialog(false);
    setInvoiceOptionData("");
    setInvoiceRemainingAmountData("");
    reset();
  };
  const selectedInvoice = watch("Invoice_Number");
  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();

    const payload = {
      po_number: data?.PurchaseOrderNo,
      invoice_number: data?.Invoice_Number,
      payment_amount: data?.invoice_amount,
      payment_type: data?.payment_type,
      remarks: data?.remarks,
      utr_number: data?.utr_number,
      payment_sub_types: [
        {
          payment_sub_id: 1,
          payment_sub_type: "penalty",
          payment_sub_type_amount: deduction ? data?.penalty ?? 0 : 0,
        },
        {
          payment_sub_id: 1,
          payment_sub_type: "tds",
          payment_sub_type_amount: deduction ? data?.tds ?? 0 : 0,
        },
        {
          payment_sub_id: 1,
          payment_sub_type: "tax",
          payment_sub_type_amount: deduction ? data?.tax ?? 0 : 0,
        },
        {
          payment_sub_id: 1,
          payment_sub_type: "discrepancy",
          payment_sub_type_amount: deduction ? data?.discrepancy ?? 0 : 0,
        },
      ],
    };

    formData.append("data", JSON.stringify(payload));
    formData.append("utr_document", document);

    dispatch(getCreatePOLeisure(formData)).then((res) => {
      if (res.payload.success) {
        setDocument("");
        setLoading(false);
        setOpenDialog(false);
        reset();
        setInvoiceOptionData("");
        setInvoiceRemainingAmountData("");
        message.success(res.payload.message);
      } else {
        setLoading(false);
        message.error(res.payload.message);
      }
    });
  };

  const handleCreateLedgerFrom = (data) => {
    dispatch(getInvoiceDetails(data.id)).then((res) => {
      if (res.payload.success) {
        const data = res?.payload?.data?.map((it) => it.invoice_number);
        const remaining_amount_data = res?.payload?.data?.map((it) => ({
          invoice_number: it.invoice_number,
          remaining_amount: it.remaining_amount,
        }));
        setInvoiceOptionData(data);
        setInvoiceRemainingAmountData(remaining_amount_data);
      }
    });
  };

  const handleAmountChange = (event) => {
    let value = event.target.value.trim();

    if (value === "") {
      setError("invoice_amount", {
        type: "manual",
        message: "Amount is required",
      });
      setValue("invoice_amount", "");
      return;
    }

    value = parseFloat(value);

    if (isNaN(value)) {
      setError("invoice_amount", {
        type: "manual",
        message: "Enter a valid number",
      });
      return;
    }

    if (value < 0) {
      setError("invoice_amount", {
        type: "manual",
        message: "Amount cannot be negative",
      });
      return;
    }

    const selectedInvoiceData = invoiceRemainingAmountData.find(
      (invoice) => invoice.invoice_number === selectedInvoice
    );

    if (!selectedInvoiceData) {
      setError("invoice_amount", {
        type: "manual",
        message: "Invoice data not found",
      });
      return;
    }

    const remainingAmount = selectedInvoiceData.remaining_amount;

    if (typeof remainingAmount !== "number") {
      setError("invoice_amount", {
        type: "manual",
        message: "Invalid remaining amount",
      });
      return;
    }

    if (value > remainingAmount) {
      setError("invoice_amount", {
        type: "manual",
        message: `Amount cannot be greater than ₹${remainingAmount}`,
      });
    } else {
      clearErrors("invoice_amount");
      setValue("invoice_amount", value, { shouldValidate: true });
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            width: "700px",
            maxWidth: "90%",
          },
        }}
      >
        <DialogTitle style={{ fontWeight: "Bold" }}>Ledger Form</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <DialogContentText>PO Number</DialogContentText>
                  <TextField
                    fullWidth
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    value={selectedRow?.order_number}
                    variant="standard"
                    name="PurchaseOrderNo"
                    {...register("PurchaseOrderNo", {
                      value: selectedRow?.order_number,
                    })}
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <DialogContentText>
                    Invoice Number<Super>*</Super>
                  </DialogContentText>

                  <Controller
                    name="Invoice_Number"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        options={invoiceOptionData}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            sx={{
                              input: {
                                fontSize: "14px",
                                fontFamily: "var(--font-family-reg)",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <DialogContentText
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    UTR Document
                    {document !== "" ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Chip
                          style={{
                            width: "150px",
                            color: "#4945FF",
                            borderRadius: "5px",
                            border: "1px solid #D9D8FF",
                            background: "#F0F0FF",
                          }}
                          label={document.name}
                          value={document.name}
                          name="chips"
                        />
                        <HighlightOffIcon
                          sx={{ color: "#4945FF", cursor: "pointer" }}
                          fontSize="medium"
                          onClick={() => setDocument("")}
                        />
                      </span>
                    ) : (
                      <label
                        htmlFor="leisureDocument"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Input
                          {...register("leisureDocument", {
                            required: false,
                          })}
                          accept="*"
                          id="leisureDocument"
                          onChange={(e) => setDocument(e.target.files[0])}
                          type="file"
                          style={{ display: "none" }}
                        />
                        <StyledOutlineButton
                          variant="outlined"
                          component="span"
                        >
                          Upload
                        </StyledOutlineButton>
                      </label>
                    )}
                  </DialogContentText>
                  {document === "" && (
                    <FormHelperText error>
                      {errors.leisureDocument?.message}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <DialogContentText>
                    UTR Number<Super>*</Super>
                  </DialogContentText>
                  <TextField
                    fullWidth
                    type="text"
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    variant="standard"
                    name="utr_number"
                    {...register("utr_number", {
                      required: "Please Enter UTR Number",
                    })}
                    error={Boolean(errors?.utr_number)}
                    helperText={errors.utr_number?.message}
                  />
                </Grid>
              </Grid>
              <br />

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <DialogContentText> Amount</DialogContentText>
                  <TextField
                    style={{ width: "100%" }}
                    type="number"
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter Invoice Amount"
                    variant="standard"
                    name="invoice_amount"
                    inputProps={{ step: "any", min: "0" }}
                    {...register("invoice_amount", {
                      valueAsNumber: true,
                      pattern: {
                        value: /^[0-9]*\.?[0-9]*$/,
                        message: "Enter a valid number",
                      },
                    })}
                    onChange={handleAmountChange}
                    disabled={paymentType === "Deduction"}
                    error={Boolean(errors?.invoice_amount)}
                    helperText={errors.invoice_amount?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DialogContentText>
                    Payment Type<Super>*</Super>
                  </DialogContentText>
                  <Select
                    style={{ width: "100%" }}
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    variant="standard"
                    labelId="demo-simple-select-standard-label"
                    id="payment_type"
                    name="payment_type"
                    error={Boolean(errors?.payment_type)}
                    {...register("payment_type", {
                      required: "Please select Payment Type",
                    })}
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select Payment
                      </span>
                    </MenuItem>

                    <MenuItem value="Advance">
                      <SelectSpan>Advance</SelectSpan>
                    </MenuItem>
                    <MenuItem value="Partial">
                      <SelectSpan>Partial</SelectSpan>
                    </MenuItem>
                    <MenuItem value="Full">
                      <SelectSpan>Full</SelectSpan>
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deduction}
                    onChange={(e) => setDeduction(e.target.checked)}
                  />
                }
                label="Deduction"
              />
              {deduction && (
                <>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <DialogContentText>Penalty</DialogContentText>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter Penalty"
                        variant="standard"
                        inputProps={{ step: "any", min: "0" }}
                        {...register("penalty", {
                          valueAsNumber: true,
                          pattern: {
                            value: /^[0-9]*\.?[0-9]*$/,
                            message: "Enter a valid number",
                          },
                        })}
                        defaultValue="0"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DialogContentText>TDS</DialogContentText>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter TDS"
                        variant="standard"
                        inputProps={{ step: "any", min: "0" }}
                        {...register("tds", {
                          valueAsNumber: true,
                          pattern: {
                            value: /^[0-9]*\.?[0-9]*$/,
                            message: "Enter a valid number",
                          },
                        })}
                        defaultValue="0"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <DialogContentText>Tax</DialogContentText>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter Tax"
                        variant="standard"
                        inputProps={{ step: "any", min: "0" }}
                        {...register("tax", {
                          valueAsNumber: true,
                          pattern: {
                            value: /^[0-9]*\.?[0-9]*$/,
                            message: "Enter a valid number",
                          },
                        })}
                        defaultValue="0"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DialogContentText>Discrepancy</DialogContentText>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter Discrepancy"
                        variant="standard"
                        inputProps={{ step: "any", min: "0" }}
                        {...register("discrepancy", {
                          valueAsNumber: true,
                          pattern: {
                            value: /^[0-9]*\.?[0-9]*$/,
                            message: "Enter a valid number",
                          },
                        })}
                        defaultValue="0"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <br></br>
              <Grid item xs={12}>
                <DialogContentText>Remarks</DialogContentText>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="standard"
                  placeholder="Enter additional notes (max 300 characters)"
                  sx={{
                    textarea: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  {...register("remarks", {
                    maxLength: {
                      value: 300,
                      message: "Maximum 300 characters allowed",
                    },
                  })}
                  helperText={errors?.remarks?.message}
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  disabled={
                    !selectedInvoice ||
                    Boolean(errors.invoice_amount) ||
                    getValues("invoice_amount") === 0 ||
                    getValues("invoice_amount") === "" ||
                    isNaN(getValues("invoice_amount"))
                  }
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                >
                  Submit
                </LoadingButton>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}></DialogActions>
      </Dialog>
      <CustomTableContainer component={Card}>
        <Table aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <TableCell>
                <TableHeaderFont>Vendor Company Name</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Supplier Code</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Order Number</TableHeaderFont>
              </TableCell>

              <TableCell align="left">
                <TableHeaderFont>Order Amount</TableHeaderFont>
              </TableCell>

              <TableCell align="left">
                <TableHeaderFont>Order Date</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Order Acceptance Date</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Status</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Invoice Status</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Invoice Date</TableHeaderFont>
              </TableCell>
              <TableCell align="center">
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell>
            </TableRow>
          </CustomTableHead>
          {data?.data?.length !== 0 ? (
            <TableBody>
              {data?.data?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.Vendor ? row?.Vendor?.company_name : "-"}
                  </TableCell>

                  <TableCell>
                    {row?.Vendor
                      ? row?.Vendor?.User?.UsersRoles[0]?.vendor_code
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row?.order_number ? row?.order_number : "-"}
                  </TableCell>
                  <TableCell>
                    {row?.order_amount ? row?.order_amount : "-"}
                  </TableCell>
                  <TableCell>
                    {row.order_date
                      ? moment(row.order_date).format("DD MMM YY")
                      : "Not Applicable"}
                  </TableCell>
                  <TableCell>
                    {row.status === "Accepted"
                      ? row.updatedAt
                        ? moment(row.updatedAt).format("DD MMM YY")
                        : "-"
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row.status ? (
                      <Chip
                        label={row.status.toUpperCase()}
                        color={row.status === "Pending" ? "warning" : "success"}
                        size="small"
                        style={{ fontSize: "9px" }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {row?.Invoices?.length ? (
                      <Chip
                        label={"SUBMITTED"}
                        color={"success"}
                        size="small"
                        style={{ fontSize: "9px" }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {row?.Invoices?.length
                      ? moment(
                          row.Invoices.reduce((latest, invoice) =>
                            new Date(invoice.createdAt) >
                            new Date(latest.createdAt)
                              ? invoice
                              : latest
                          ).updatedAt
                        ).format("DD MMM YY")
                      : "Not Applicable"}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Tooltip title="View Order">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/orders-list-view/" + `${row.id}`}
                      >
                        <RemoveRedEye
                          style={{
                            fill: "royalblue",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      </Link>
                    </Tooltip>
                    {ledgerStatusData?.is_granted && (
                      <IconButton
                        onClick={() => {
                          setDocument("");
                          setSelectedRow(row);
                          setOpenDialog(true);
                          handleCreateLedgerFrom(row);
                        }}
                      >
                        <Tooltip title="Ledger Form">
                          <AddBoxOutlinedIcon color="action" fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 0 * emptyRows }}></TableRow>
              )} */}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={9}>
                  <CustomTableContainer>
                    {/* Todo fix permissions */}
                    <EmptyContainer role={role[0]} />
                  </CustomTableContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={data?.pagination?.total_page}
            page={page}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
    </>
  );
}

export default AdminOrdersTableContent;
