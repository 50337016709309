import React, { useEffect } from "react";
import "./components/login.css";
import LeftAuth from "../../common/sharedComponents/LeftScreen";
import RightLogin from "./components/loginRightScreen";
import { useDispatch, useSelector } from "react-redux";
import { login, tenant } from "./redux/loginSlice";
import { domainName } from "../../utilities/helper";
import { useLocation } from "react-router-dom";
// import { history } from "../../app/history";
// import { roleBasedNavigation } from "../../routes/PrivateRoutes";

const Login = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const { loading } = useSelector((state) => state.login);
  const onSubmit = (payload) => {
    dispatch(login(payload));
    // .then((res) => {
    //   const { roles, success } = res.payload;
    //   if (success) {
    //     const { from } = location.state || roleBasedNavigation(roles[0]);
    //     history.replace(from);
    //   }
    // });
  };
  useEffect(() => {
    dispatch(tenant({ domain_url: domainName }));
  }, [dispatch]);
  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div style={{ position: "absolute" }}>
          <LeftAuth />
        </div>
        <div style={{ position: "absolute" }}>
          <RightLogin onSubmit={onSubmit} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default Login;
