import {
  Grid,
  MenuItem,
  Menu,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";

import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminOrdersTableContent from "./components/adminOrdersTableContent";
import LoadingButton from "@mui/lab/LoadingButton";
// for dialog box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import { read, utils } from "xlsx";

import { addExcelData, getDirectOrders } from "./redux/adminOrdersSlice";
import { useDispatch, useSelector } from "react-redux";

import LocalStorage from "../../utilities/localStorage";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
function AdminOrdersTable() {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [fileOpen, setFileOpen] = React.useState(false);
  const [emptyOpen, setEmptyOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const role = LocalStorage.getItem("tata_login_user").roles;
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [searchFilter, setSearchFilter] = React.useState("");
  const { directOrdersList } = useSelector((state) => state.adminOrders);

  const handleFileUpload = (e) => {
    const files = e.target.files;

    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          if (rows.length !== 0) {
            setOpen(true);
            setData(rows);
          } else {
            setEmptyOpen(true);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadData = () => {
    dispatch(addExcelData({ data })).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        setOpen(false);
        setFileOpen(false);
      } else {
        setLoading(false);
        setOpen(false);
        setFileOpen(false);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setEmptyOpen(false);
    setFileOpen(false);
  };
  const orders = (payload) => {
    dispatch(getDirectOrders(payload));
  };
  const refetch = () => {
    orders({
      page: page,
      per_page: per_page,
      search: search,
      searchFilter: searchFilter,
    });
  };
  React.useEffect(() => {
    orders({
      page: page,
      per_page: per_page,
      search: search,
      searchFilter: searchFilter,
    });
  }, [page, per_page]);
  const handleSearchClick = () => {
    refetch();
  };
  const handleSearchClear = () => {
    setSearch("");
    refetch();
  };
  const handleSearchFilterChange = (event) => {
    setSearchFilter(event.target.value);
  };

  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ whiteSpace: "nowrap" }} item>
            Direct Orders
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" size="small">
                <InputLabel>Filter</InputLabel>
                <Select
                  value={searchFilter}
                  onChange={handleSearchFilterChange}
                  label="Filter"
                  sx={{ width: "200px" }}
                >
                  <MenuItem value="company_name">Company Name</MenuItem>
                  <MenuItem value="vendor_code">Vendor Code</MenuItem>
                  <MenuItem value="order_number">Order Number</MenuItem>
                  {/* <MenuItem value="order_amount">Order Amount</MenuItem> */}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <Button
                variant="contained"
                size="large"
                onClick={handleSearchClick}
              >
                <Search />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <AdminOrdersTableContent
          setPages={setPage}
          setPerPages={setPer_page}
          isLoading={loading}
          refetch={refetch}
          fileOpen={fileOpen}
          data={directOrdersList}
        />
      </StepperContentHolder>
      {/* Todo fix permissions */}

      {/* add file dialog */}
      <Dialog
        open={fileOpen}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "Bold" }}
          id="draggable-dialog-title"
        >
          Add Excel File
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Add an excel file to upload</DialogContentText>
          <DialogContentText>
            <input
              type="file"
              fullWidth
              accept=".xlsx .xls .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleFileUpload}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}>
          <Button
            autoFocus
            onClick={handleClose}
            style={{ border: "#4059e5 solid 1px", height: "30px" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog box for conformation */}
      <Dialog
        open={open}
        onClose={handleClose}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Upload Excel Data
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure add excel data?</DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}>
          <Button
            autoFocus
            onClick={handleClose}
            style={{ border: "#4059e5 solid 1px", height: "30px" }}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "70px",
              height: "30px",
            }}
            color="primary"
            variant="contained"
            onClick={uploadData}
            loading={loading}
            loadingPosition="start"
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* this dialod for no data in excel */}
      <Dialog
        open={emptyOpen}
        onClose={handleClose}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Empty File
        </DialogTitle>
        <DialogContent>
          <DialogContentText>please enter data in excel</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ border: "#4059e5 solid 1px", height: "30px" }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* <AddButton
                style={{ backgroundColor: "#4059e5" }}
                replace
                to="/app/orders"
              ></AddButton> */}
      {/* <StyledOutlineButton
                  variant="outlined"
                  component="span"
                  style={{ borderColor: "#4059e5" }}
                >
                  <a href={excel} download style={{ textDecoration: "none" }}>
                    Download Excel
                  </a>
                </StyledOutlineButton> */}

      {/* dropdown */}
    </div>
  );
}

export default AdminOrdersTable;
