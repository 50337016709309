import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_vendors,
  get_vendor_emails,
  orders,
  get_orders,
  view_AdminOrderDetails,
  add_excel_data,
  get_materials,
  createPOLeisure,
  get_direct_orders,
  get_ref_orders,
  get_ledger_status,
} from "./adminOrdersAPI";

const initialState = {
  loading: false,
  vendors: [],
  materials: [],
  vendoremails: [],
  ordersList: [],
  ledgerStatusData: {},
  directOrdersList: [],
  refOrdersList: [],
  adminOrderDetails: [],
};
const actions = {
  GETVENDORS: "getVendors/GETVENDORS",
  GETVENDOREMAILS: "getVendorsemails/GETVENDOREMAILS",
  GETMATERIALS: "getMaterials/GETMATERIALS",
  CREATEORDER: "createOrder/CREATEORDER",
  GETORDERS: "getOrders/GETORDERS",
  GETDIRECTORDERS: "getDirectOrders/GETDIRECTORDERS",
  GETLEDGERSTATUS: "getLedgerStatus/GETLEDGERSTATUS",
  GETREFORDERS: "getRefOrders/GETREFORDERS",
  ADDEXCEL: "addExcel/ADDEXCEL",
  GETCREATEPOLEISURE: "getCreatePOLeisure/GETCREATEPOLEISURE",
  VIEWADMINORDERDETAILS: "getAdminOrderDetails/VIEWADMINORDERDETAILS",
};

export const addExcelData = createAsyncThunk(
  actions.ADDEXCEL,
  async (payload) => {
    const response = await add_excel_data(payload);
    return response;
  }
);
export const getCreatePOLeisure = createAsyncThunk(
  actions.GETCREATEPOLEISURE,
  async (payload) => {
    const response = await createPOLeisure(payload);
    return response;
  }
);
export const getVendors = createAsyncThunk(
  actions.GETVENDORS,
  async (payload) => {
    const response = await get_vendors(payload);
    return response;
  }
);

export const getMaterials = createAsyncThunk(
  actions.GETMATERIALS,
  async (payload) => {
    const response = await get_materials(payload);
    return response;
  }
);

export const getVendorsemails = createAsyncThunk(
  actions.GETVENDOREMAILS,
  async (payload) => {
    const response = await get_vendor_emails(payload);
    return response;
  }
);

export const createOrder = createAsyncThunk(
  actions.CREATEORDER,
  async (payload) => {
    const response = await orders(payload);
    return response;
  }
);

export const getAdminOrderDetails = createAsyncThunk(
  actions.VIEWADMINORDERDETAILS,
  async (payload) => {
    const response = await view_AdminOrderDetails(payload);
    return response;
  }
);

export const getOrders = createAsyncThunk(
  actions.GETORDERS,
  async (payload) => {
    const response = await get_orders(payload);
    return response;
  }
);
export const getDirectOrders = createAsyncThunk(
  actions.GETDIRECTORDERS,
  async (payload) => {
    const response = await get_direct_orders(payload);
    return response;
  }
);
export const getLedgerStatus = createAsyncThunk(
  actions.GETLEDGERSTATUS,
  async (payload) => {
    const response = await get_ledger_status(payload);
    return response;
  }
);
export const getRefOrders = createAsyncThunk(
  actions.GETREFORDERS,
  async (payload) => {
    const response = await get_ref_orders(payload);

    return response;
  }
);

export const adminOrdersSlice = createSlice({
  name: "adminOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendors = data;
        if (success) {
          /*   message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendors.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getMaterials.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMaterials.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.materials = data;
        if (success) {
          /*   message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getVendorsemails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorsemails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendoremails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorsemails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAdminOrderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminOrderDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminOrderDetails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminOrderDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createOrder.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(addExcelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addExcelData.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addExcelData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getCreatePOLeisure.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCreatePOLeisure.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getCreatePOLeisure.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.ordersList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getLedgerStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLedgerStatus.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.ledgerStatusData = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLedgerStatus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getDirectOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDirectOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.directOrdersList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getDirectOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getRefOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRefOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;

        state.loading = false;
        state.refOrdersList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getRefOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminOrdersActions = adminOrdersSlice.actions;
export const { resetadminOrdersDetails } = adminOrdersSlice.actions;

export default adminOrdersSlice.reducer;
