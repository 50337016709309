import {
  CardContent,
  Chip,
  Grid,
  Typography,
  TableHead,
  Tooltip,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { getAdminOrderDetails } from "../redux/adminOrdersSlice";
import InfoIcon from "@mui/icons-material/Info";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  getInvoiceDetails,
  getLedgerDetails,
} from "../../userOrders/redux/userOrdersSlice";
import moment from "moment";

function AdminOrdersViewContent() {
  const { adminOrderDetails } = useSelector((state) => state.adminOrders);
  const { ledger_details, invoice_details } = useSelector(
    (state) => state.userOrders
  );
  const params = useParams();
  const dispatch = useDispatch();

  const order_id = params.orderid;
  const viewOrderDetails = (order_id) => {
    dispatch(getAdminOrderDetails(order_id));
  };

  useEffect(() => {
    viewOrderDetails(order_id);
    dispatch(getLedgerDetails(order_id));
    dispatch(getInvoiceDetails(order_id));
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Order#
            {adminOrderDetails.order_number
              ? adminOrderDetails.order_number
              : "Not Applicable"}
          </Grid>
          {adminOrderDetails?.status !== "Accepted" ? (
            ""
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "100px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label="Accepted"
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={4} md={4}>
              <Typography className="label"> COMPANY NAME</Typography>
              <VendorCardLabel>Jaslok Hospital</VendorCardLabel>
            </Grid>
            {/* <Grid item xs={6} md={6}>
              <Typography className="label"> TYPE</Typography>
              <VendorCardLabel>
                {adminOrderDetails.type
                  ? adminOrderDetails.type
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid> */}
            {/* <Grid item xs={6} md={6}>
              <Typography className="label">DEPARTMENT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.organizational_unit
                  ? adminOrderDetails.organizational_unit
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid> */}
            <Grid item xs={4} md={4}>
              <Typography className="label">ORDER NUMBER</Typography>
              <VendorCardLabel>
                {adminOrderDetails.order_number
                  ? adminOrderDetails.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography className="label">AMOUNT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.order_amount
                  ? adminOrderDetails.order_amount
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            {/* <Grid item xs={6} md={6}>
              <Typography className="label">PROJECT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.project
                  ? adminOrderDetails.project
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid> */}
            {/* <Grid item xs={6} md={6}>
              <Typography className="label">VERSION NUMBER</Typography>
              <VendorCardLabel>
                {adminOrderDetails.version_number
                  ? adminOrderDetails.version_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid> */}
          </Grid>
          <br />
          {/* <br /> */}
          <VendorCardTitle>Item Details</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Name</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Unit Price</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Total Price</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminOrderDetails?.item_list ? (
                  adminOrderDetails.item_list.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity !== null
                            ? row.quantity
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(row.quantity * row.unit_price).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>Fetching items..</TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          {/* <br /> */}
          <VendorCardTitle>Ledger Details</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#e1ebfa" }}>
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Invoice Number</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>UTR Number</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Payment Type</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Payment Amount</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Penalty</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>TDS</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>TAX</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Discrepancy</TableHeaderFont>
                  </TableCell>

                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>UTR Document</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Remarks</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ledger_details && ledger_details.length > 0 ? (
                  ledger_details.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        width="10%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row?.Invoice?.invoice_number
                          ? row.Invoice.invoice_number
                          : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row?.utr_number ? row.utr_number : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row.payment_type !== null ? row.payment_type : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row.payment_amount !== null ? row.payment_amount : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row.PaymentSubTypes[0]?.payment_sub_type_amount
                          ? row.PaymentSubTypes[0].payment_sub_type_amount
                          : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row.PaymentSubTypes[1]?.payment_sub_type_amount
                          ? row.PaymentSubTypes[1].payment_sub_type_amount
                          : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row.PaymentSubTypes[2]?.payment_sub_type_amount
                          ? row.PaymentSubTypes[2].payment_sub_type_amount
                          : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row.PaymentSubTypes[3]?.payment_sub_type_amount
                          ? row.PaymentSubTypes[3].payment_sub_type_amount
                          : "-"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row?.utr_document ? (
                          <Tooltip title="Download Document">
                            <CustomDocumentLink
                              href={row?.utr_document}
                              target="_blank"
                            >
                              <InsertDriveFileOutlinedIcon
                                style={{
                                  marginTop: "-1px",
                                  marginRight: "3px",
                                  height: "12px",
                                  width: "12px",
                                }}
                              />
                              {row?.utr_number} UTR Doc
                            </CustomDocumentLink>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        {row.remarks !== null ? row.remarks : "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      style={{
                        textAlign: "center",
                        padding: "16px",
                        fontSize: "14px",
                      }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          {/* <br /> */}
          <VendorCardTitle>Invoice Details</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Invoice Number</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>invoice amount</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>invoice Creation Date</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Invoice Document</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice_details.length > 0 ? (
                  invoice_details.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.invoice_number ? row.invoice_number : "-"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.invoice_amount ? row.invoice_amount : "-"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.createdAt
                            ? moment(row.createdAt).format("DD MMM YY")
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row?.Invoice_document?.invoice_document ? (
                            <Tooltip title="Download Document">
                              <CustomDocumentLink
                                href={row?.Invoice_document?.invoice_document}
                                target="_blank"
                              >
                                <InsertDriveFileOutlinedIcon
                                  style={{
                                    marginTop: "-1px",
                                    marginRight: "3px",
                                    height: "12px",
                                    width: "12px",
                                  }}
                                />
                                {row?.invoice_number} Invoice doc
                              </CustomDocumentLink>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      style={{
                        textAlign: "center",
                        padding: "16px",
                        fontSize: "14px",
                      }}
                    >
                      No invoice records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography className="label">ORDER DOCUMENT</Typography>
              <VendorCardLabel>
                {adminOrderDetails.Order_document ? (
                  adminOrderDetails.Order_document ? (
                    <Tooltip title="Download Document">
                      <CustomDocumentLink
                        href={adminOrderDetails.Order_document}
                        target="_blank"
                      >
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Order document
                      </CustomDocumentLink>
                    </Tooltip>
                  ) : (
                    <Typography className="label">
                      <InfoIcon
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no order document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <InfoIcon
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no order document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>
          </Grid>
          {/* <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {adminOrderDetails?.Supporting_document !== undefined &&
              adminOrderDetails?.Supporting_document !== null &&
              adminOrderDetails?.Supporting_document.length > 0 ? (
                adminOrderDetails?.Supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <Tooltip title="Download Document">
                      <CustomDocumentLink
                        key={index}
                        href={item}
                        target="_blank"
                      >
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </Tooltip>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid> */}
        </CardContent>
      </StyledCard>
    </>
  );
}
export default AdminOrdersViewContent;

export const StyledCard = materialStyles(Card)`
box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
flex-direction: column;
border: none;
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 1em;
`;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
