import React from "react";
import { NavLink } from "react-router-dom";
import LocalStorage from "../../../utilities/localStorage";

function UserPrivilegesContent() {
  const clientId = LocalStorage.getItem("client")?.id;
  return (
    <>
      <li>
        <NavLink to="/app/user-privileges/pr-creation" end={true}>
          Purchase Requisition Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/ledger-creation" end={true}>
          Ledger Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/direct-order-list-view" end={true}>
          Direct Order List View privileges
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/reference-order-list-view" end={true}>
          Reference Order List View privileges
        </NavLink>
      </li>
      {clientId !== 5 && (
        <li>
          <NavLink to="/app/workflow-steps" end={true}>
            Download PR Audit Report
          </NavLink>
        </li>
      )}

      {/* <li>
        <NavLink to="/app/user-privileges/po-creation" end={true}>
          Purchase Order Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/schedule-creation" end={true}>
          Schedule Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/asn-creation" end={true}>
          ASN Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/grn-creation" end={true}>
          GRN Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/invoice-creation" end={true}>
          Invoice Creation
        </NavLink>
      </li> */}
    </>
  );
}

export default UserPrivilegesContent;
