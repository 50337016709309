import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  // Tooltip,
  useTheme,
} from "@mui/material";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableHeaderFont,
} from "../../../../styles/tableContent.style";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { ApproveButton } from "../../../vendorRegistration/components/ViewvendorDetails";
import {
  getPrGrantedUsers,
  getPrPendingUsers,
  getPrRevokedUsers,
  revokePRPrivilege,
  userPrivilegesGrantsForLedger,
} from "../../redux/userPrivilegesSlice";
import { useEffect } from "react";
import { EmptyContainer } from "../../../../utilities/helper";

function ReferenceOrderTableContent({
  data,
  type,
  setPages,
  setPerPages,
  isLoading,
  refetch,
}) {
  const { ledgerPrivilegesLoading } = useSelector(
    (state) => state.userPrivileges
  );
  const dispatch = useDispatch();

  const [selectedItems, setSelectedItems] = React.useState([]);
  const [per_page, setPer_page] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  const columns = [
    { label: "Sr. No.", key: "index" },
    { label: "Name", key: "first_name" },
    { label: "Email", key: "contact_person_email" },
  ];
  console.log("ddddd", data);

  const handleSelectAll = () => {
    const newArray = data?.data?.map((itemCode) => itemCode?.id);
    if (selectedItems.length === newArray.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(newArray);
    }
  };
  const handleCheckboxChange = (code) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(code)
        ? prevSelected.filter((itemCode) => itemCode !== code)
        : [...prevSelected, code]
    );
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    const payload = {
      userIds: selectedItems,
      doc_type: 4,
      is_granted: type === "pending" || type === "revoked" ? true : false,
      is_revoked: type === "granted" ? true : false,
    };
    dispatch(userPrivilegesGrantsForLedger(payload)).then((response) => {
      if (response.payload.success === true) {
        setOpen(false);
        refetch();
        setSelectedItems([]);
      }
    });
  };
  useEffect(() => {
    setPages(page);
    setPerPages(per_page);
  }, [page, per_page]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {type === "granted" ? "revoke" : "grant"}{" "}
            Reference Order View privileges to the selected users?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            onClick={handleSave}
            autoFocus
            size="small"
            disabled={ledgerPrivilegesLoading}
          >
            {ledgerPrivilegesLoading ? <CircularProgress size={24} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <CircularProgress sx={{ display: "block", margin: "0 auto" }} />
        </Box>
      ) : (
        <CustomTableContainer component={Card}>
          <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
            <CustomTableHead>
              <TableRow>
                <TableCell padding="checkbox" align="left">
                  <Checkbox
                    indeterminate={selectedItems?.length}
                    checked={
                      data?.data?.length > 0 &&
                      selectedItems?.length === data?.data?.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.key} align="left">
                    <TableHeaderFont>{column.label}</TableHeaderFont>
                  </TableCell>
                ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {data?.refOrderPrivilegesList?.data?.length !== 0 ? (
                data?.refOrderPrivilegesList?.data?.map((item, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: "#fafcff",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.includes(item?.id)}
                        onChange={() => handleCheckboxChange(item?.id)}
                      />
                    </TableCell>
                    {columns.map((column) => (
                      <>
                        <TableCell
                          key={column.key}
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "left",
                            color: "inherit",
                          }}
                        >
                          {column?.key === "index"
                            ? index + 1
                            : item?.[column?.key]
                            ? item?.[column?.key]
                            : "-"}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    <CustomTableContainer>
                      <EmptyContainer
                        text={`There are no ${type} users to display`}
                      />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Grid
            container
            className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
            sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
          >
            <Grid className="d-flex align-items-center gap-2">
              <span>Rows per page: </span>
              <select
                value={per_page}
                onChange={(e) => setPer_page(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </Grid>
            <Pagination
              sx={{
                width: "max-content",
              }}
              color="primary"
              variant="text"
              onChange={(_, newValue) => setPage(newValue)}
              count={data?.refOrderPrivilegesList?.pagination?.total_page}
              page={page}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Grid>
        </CustomTableContainer>
      )}
      <br></br>
      <ApproveButton
        onClick={(e) => handleClickOpen()}
        variant="outlined"
        style={{
          height: "30px",
          width: "100px",
          float: "right",
        }}
      >
        {type === "granted" ? "Revoke" : "Grant"}
      </ApproveButton>
    </>
  );
}
export default ReferenceOrderTableContent;
