import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import DirectOrderTablePrivilegesViewContent from "./components/directOrderTablePrivilegesViewContent";
import ClearIcon from "@mui/icons-material/Clear";

function DirectOrderTableView() {
  const [search, setSearch] = React.useState();
  const [search_key, setSearch_key] = React.useState("");

  const handleSearchClick = () => {
    setSearch_key(search);
  };
  const handleSearchClear = () => {
    setSearch_key("");
    setSearch("");
  };

  return (
    <div>
      <TitleHolder style={{ paddingBottom: "5px" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Direct Order List View Privileges
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
            xs={6}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                InputProps={{
                  endAdornment: search && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleSearchClear}
                        edge="end"
                        size="small"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                size="large"
                onClick={handleSearchClick}
              >
                <Search />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </TitleHolder>

      <StepperContentHolder style={{ paddingTop: "5px" }}>
        <DirectOrderTablePrivilegesViewContent search={search_key} />
      </StepperContentHolder>
    </div>
  );
}

export default DirectOrderTableView;
