import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  approve_order,
  get_orders,
  uploadPOinvoice,
  vendorDirectOrdersList,
  view_invoiceDetails,
  view_ledgerDetails,
  view_OrderDetails,
} from "./userOrdersAPI";

const initialState = {
  loading: false,
  ordersList: [],
  vendorDirectOrdersList: [],
  order_details: [],
  ledger_details: [],
  invoice_details: [],
};
const actions = {
  GETORDERS: "getOrders/GETORDERS",
  GETVENDORDIRECTORDERS: "getVendorDirectOrders/GETVENDORDIRECTORDERS",
  GETUPLOADPOINVOICE: "getUploadPOinvoice/GETUPLOADPOINVOICE",
  VIEWORDERDETAILS: "getOrderDetails/VIEWORDERDETAILS",
  APPROVEORDER: "approveOrder/APPROVEORDER",
  LEDGERDETAIL: "ledgerDetails/LEDGERDETAIL",
  INVOICEDETAIL: "InvoiceDetails/INVOICEDETAIL",
};

export const getOrders = createAsyncThunk(
  actions.GETORDERS,
  async (payload) => {
    const response = await get_orders(payload);
    return response;
  }
);
export const getVendorDirectOrders = createAsyncThunk(
  actions.GETVENDORDIRECTORDERS,
  async (payload) => {
    const response = await vendorDirectOrdersList(payload);
    return response;
  }
);
export const getUploadPOinvoice = createAsyncThunk(
  actions.GETUPLOADPOINVOICE,
  async (payload) => {
    const response = await uploadPOinvoice(payload);
    return response;
  }
);

export const getOrderDetails = createAsyncThunk(
  actions.VIEWORDERDETAILS,
  async (payload) => {
    const response = await view_OrderDetails(payload);
    return response;
  }
);
export const getLedgerDetails = createAsyncThunk(
  actions.LEDGERDETAIL,
  async (payload) => {
    const response = await view_ledgerDetails(payload);
    return response;
  }
);
export const getInvoiceDetails = createAsyncThunk(
  actions.INVOICEDETAIL,
  async (payload) => {
    const response = await view_invoiceDetails(payload);
    return response;
  }
);

export const approveOrder = createAsyncThunk(
  actions.APPROVEORDER,
  async (payload) => {
    const response = await approve_order(payload);
    return response;
  }
);

export const userOrdersSlice = createSlice({
  name: "userOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.ordersList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getVendorDirectOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorDirectOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.vendorDirectOrdersList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorDirectOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getUploadPOinvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUploadPOinvoice.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;

        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getUploadPOinvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getOrderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.order_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getLedgerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLedgerDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.ledger_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLedgerDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getInvoiceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.invoice_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getInvoiceDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveOrder.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveOrder.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const userOrdersActions = userOrdersSlice.actions;
export const { resetuserOrdersDetails } = userOrdersSlice.actions;

export default userOrdersSlice.reducer;
