import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_pr_granted_users,
  get_pr_pending_users,
  get_pr_revoked_users,
  grant_pr_privilege,
  revoke_pr_privilege,
  user_PrivilegesListForDirectOrder,
  user_privilegesGrantsForLedger,
  user_PrivilegesListForLedger,
  user_PrivilegesListForRefOrder,
  user_PrivilegesListForPR,
} from "./userPrivilegesAPI";

const initialState = {
  loading: false,
  prGrantedList: [],
  prPendingList: [],
  prRevokedList: [],
  ledgerPrivilegesList: {},
  directOrderPrivilegesList: {},
  refOrderPrivilegesList: {},
  prPrivilegesList: {},
  ledgerPrivilegesLoading: false,
};
const actions = {
  GETPRGRANTEDUSERS: "getPrGrantedUsers/GETPRGRANTEDUSERS",
  GETPRPENDINGUSERS: "getPrPendingUsers/GETPRPENDINGUSERS",
  GETPRREVOKEDUSERS: "getPrRevokedUsers/GETPRREVOKEDUSERS",
  GRANTPRPRIVILEGE: "grantPRPrivilege/GRANTPRPRIVILEGE",
  REVOKEPRPRIVILEGE: "revokePRPrivilege/REVOKEPRPRIVILEGE",
  GETUSEPRIVILEGESLISTFORLEDGER:
    "userPrivilegesListForLedger/GETUSEPRIVILEGESLISTFORLEDGER",
  GETUSEPRIVILEGESLISTFORDIRECTORDER:
    "userPrivilegesListForDirectOrder/GETUSEPRIVILEGESLISTFORDIRECTORDER",
  GETUSEPRIVILEGESLISTFORREFORDER:
    "userPrivilegesListForDirectOrder/GETUSEPRIVILEGESLISTFORREFORDER",
  GETUSEPRIVILEGESLISTFORPR:
    "userPrivilegesListForPR/GETUSEPRIVILEGESLISTFORPR",
  GETUSERPRIVILEGESGRANTSFORLEDGER:
    "userPrivilegesGrantsForLedger/GETUSERPRIVILEGESGRANTSFORLEDGER",
};

export const getPrGrantedUsers = createAsyncThunk(
  actions.GETPRGRANTEDUSERS,
  async (payload) => {
    const response = await get_pr_granted_users(
      payload?.page,
      payload?.per_page
      // payload?.search
    );
    return response;
  }
);

export const getPrPendingUsers = createAsyncThunk(
  actions.GETPRPENDINGUSERS,
  async (payload) => {
    const response = await get_pr_pending_users(
      payload?.page,
      payload?.per_page
      // payload?.search
    );
    return response;
  }
);

export const getPrRevokedUsers = createAsyncThunk(
  actions.GETPRREVOKEDUSERS,
  async (payload) => {
    const response = await get_pr_revoked_users(
      payload?.page,
      payload?.per_page
      // payload?.search
    );
    return response;
  }
);

export const grantPRPrivilege = createAsyncThunk(
  actions.GRANTPRPRIVILEGE,
  async (payload) => {
    const response = await grant_pr_privilege(payload);
    return response;
  }
);

export const revokePRPrivilege = createAsyncThunk(
  actions.REVOKEPRPRIVILEGE,
  async (payload) => {
    const response = await revoke_pr_privilege(payload);
    return response;
  }
);
export const userPrivilegesListForLedger = createAsyncThunk(
  actions.GETUSEPRIVILEGESLISTFORLEDGER,
  async (payload) => {
    const response = await user_PrivilegesListForLedger(payload);
    return response;
  }
);
export const userPrivilegesListForDirectOrder = createAsyncThunk(
  actions.GETUSEPRIVILEGESLISTFORDIRECTORDER,
  async (payload) => {
    const response = await user_PrivilegesListForDirectOrder(payload);
    return response;
  }
);
export const userPrivilegesListForRefOrder = createAsyncThunk(
  actions.GETUSEPRIVILEGESLISTFORREFORDER,
  async (payload) => {
    const response = await user_PrivilegesListForRefOrder(payload);
    return response;
  }
);
export const userPrivilegesListForPR = createAsyncThunk(
  actions.GETUSEPRIVILEGESLISTFORPR,
  async (payload) => {
    const response = await user_PrivilegesListForPR(payload);
    return response;
  }
);
export const userPrivilegesGrantsForLedger = createAsyncThunk(
  actions.GETUSERPRIVILEGESGRANTSFORLEDGER,
  async (payload) => {
    const response = await user_privilegesGrantsForLedger(payload);
    return response;
  }
);
export const userPrivilegesSlice = createSlice({
  name: "userPrivileges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrGrantedUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrGrantedUsers.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          data: { data, pagination },
        } = action.payload;
        state.loading = false;
        state.prGrantedList = data;
        state.pagination = pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrGrantedUsers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(getPrPendingUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrPendingUsers.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          data: { data, pagination },
        } = action.payload;
        state.loading = false;
        state.prPendingList = data;
        state.pagination = pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrPendingUsers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(getPrRevokedUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrRevokedUsers.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          data: { data, pagination },
        } = action.payload;
        state.loading = false;
        state.prRevokedList = data;
        state.pagination = pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrRevokedUsers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(grantPRPrivilege.pending, (state) => {
        state.loading = true;
      })
      .addCase(grantPRPrivilege.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(grantPRPrivilege.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(revokePRPrivilege.pending, (state) => {
        state.loading = true;
      })
      .addCase(revokePRPrivilege.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(revokePRPrivilege.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(userPrivilegesListForLedger.pending, (state) => {
        state.loading = true;
      })
      .addCase(userPrivilegesListForLedger.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.ledgerPrivilegesList = data;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(userPrivilegesListForLedger.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(userPrivilegesListForDirectOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(userPrivilegesListForDirectOrder.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.directOrderPrivilegesList = data;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(userPrivilegesListForDirectOrder.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(userPrivilegesListForRefOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(userPrivilegesListForRefOrder.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.refOrderPrivilegesList = data;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(userPrivilegesListForRefOrder.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(userPrivilegesListForPR.pending, (state) => {
        state.loading = true;
      })
      .addCase(userPrivilegesListForPR.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.prPrivilegesList = data;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(userPrivilegesListForPR.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(userPrivilegesGrantsForLedger.pending, (state) => {
        state.ledgerPrivilegesLoading = true;
      })
      .addCase(userPrivilegesGrantsForLedger.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.ledgerPrivilegesLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(userPrivilegesGrantsForLedger.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.ledgerPrivilegesLoading = false;
        message.error(msg);
      });
  },
});
export const userPrivilegesActions = userPrivilegesSlice.actions;
export const { resetuserPrivilegesDetails } = userPrivilegesSlice.actions;

export default userPrivilegesSlice.reducer;
